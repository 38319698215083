<template>
  <div id="app">
    <Header></Header>
    <transition :name="transitionName">
      <router-view />
    </transition>
  </div>
</template>
<script lang="js">

import { Component, Vue } from 'vue-property-decorator'
import Header from '@/views/Header.vue'
import html2pdf from 'html2pdf.js'
import { Store } from 'vuex'
import html2canvas from 'html2canvas'

@Component({
  name: 'App',
  components: {
    Header
  },
  declare: {
    Store
  },
  data () {
    return {
      fData: '',
      transitionName: 'fade'
    }
  },
  beforeRouteUpdate (to, from, next) {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    next()
  },
  methods: {
    saveFile () {
      const storeData = this.$store.state
      const data = JSON.stringify(storeData)
      const blob = new Blob([data], { type: 'text/plain' })
      const e = document.createEvent('MouseEvents')
      const a = document.createElement('a')
      a.download = 'cv2.json'
      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent('click', true, false)
      a.dispatchEvent(e)
    },
    saveAs (data, name) {
      const e = document.createEvent('MouseEvents')
      const a = document.createElement('a')
      a.download = name
      a.href = data
      a.dataset.downloadurl = ['image/jpg', a.download, a.href].join(':')
      e.initEvent('click', true, false)
      a.dispatchEvent(e)
      // this.$refs.print.appendChild(a)
      // a.click()
    },
    openFile () {
      const input = document.createElement('input')
      input.type = 'file'
      input.onchange = e => {
        if (e.target.files[0]) {
          const file = e.target.files[0]
          const reader = new FileReader()
          reader.readAsText(file, 'UTF-8')
          reader.onload = readerEvent => {
            const content = readerEvent.target.result
            try {
              const newStore = JSON.parse(content)
              // sanitise ids
              newStore.work_history
                .forEach((r, index) => {
                  r.id = index
                })
              this.$store.commit('importStore', newStore)
            } catch (err) {
              alert('Error opening file')
            }
          }
        }
      }
      input.click()
    },
    exportToImg: async function () {
      const doc = document.querySelector('.print')

      html2canvas(doc, { logging: true, letterRendering: true, allowTaint: false, useCORS: true, scale: 1920 * 2 / window.innerWidth, backgroundColor: null }).then(canvas => {
        this.saveAs(canvas.toDataURL('image/jpg'), 'FastFest.jpg')
      }).catch((error) => {
        console.log('ERROR SAVING IMAGE', error)
      })
    },
    exportToPDF: async function () {
      const doc = document.querySelector('.print')
      html2pdf(doc, {
        margin: 0,
        filename: `${this.saveName}`,
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          dpi: 192,
          letterRendering: true,
          logging: false,
          allowTaint: false,
          scale: 1920 * 2 / window.innerWidth
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait'
        }
      })
    },
    clonePages: async function () {
      await document.querySelectorAll('.a4').forEach(
        el => {
          const clone = el.cloneNode(['deep'])
          // clone.classList.add('expand')
          clone.style.display = 'block'
          document.querySelector('.print').appendChild(clone)
        }
      )
    },
    async expandAll () {
      this.expand0 = this.expand1 = this.expand2 = true
    },
    async savePDF () {
      // document.querySelector('.print').innerHTML = ''
      // await this.expandAll()
      // await this.clonePages()
      //  .then(() => {
      // try {
      await setTimeout(() => this.exportToPDF(), 500)
      // } catch (err) {
      // console.log('ERROR SAVING PDF', err)
      // } finally {
      //   this.exportToImg()
      // }
      // }
    }
  },
  computed: {
    funcData: {
      get: function () { return this.$store.state.openSave }
    },
    saveName: {
      get: function () { return this.$store.state.name.replace(' ', '_') + '_cv.pdf' }
    }
  },
  watch: {
    funcData: function () {
      if (this.funcData.toString() === 'export') {
        this.savePDF()
      }
      if (this.funcData.toString() === 'open') {
        this.openFile()
      }
      if (this.funcData.toString() === 'save') {
        this.saveFile()
      }
      this.$store.commit('setOpenSave', 'false')
    }
  }
})
export default class App extends Vue {}
</script>
<style lang="scss">
html {
  background-color: #ececec;
  max-height: 120vh !important;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  width: 100vw;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: 120vh !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100vw;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
