<template>
  <div class="bg-gray-800 text-white text-xl inline-flex w-full" id="nav">
    <div class="logo">
    <h1 class="mr-4 text-left w-1/3">C<img alt="Vue logo" class="little-logo" src="../assets/logo.png">ue</h1>
    <small class="sm:hidden md:block">An easy tool to create pdf format cv's</small>
    <i class="fas fa-bars float-right mob-menu" @click="menu = !menu"></i>
    </div>
    <ul class="flex ml-auto no-mob">
      <li class="mr-3">
        <select class="text-white bg-gray-500 py-1 px-3 capitalize" v-model="selectedTheme">
          <option class="text-gray-300 bg-gray-500 py-1 px-3 capitalize" v-for="theme in themes" :value="theme" :key="theme">{{theme}}</option>
        </select>
      </li>
      <li class="mr-3">
        <router-link class="inline-block border-white-500 rounded py-1 px-3 bg-white-500 text-white font-mono" to="/">Details</router-link>
      </li>
      <li class="mr-3">
        <router-link class="inline-block border-white-500 rounded py-1 px-3 bg-white-500 text-white font-mono" v-if="a4Data.profile.length > 10" to="/pages">Preview</router-link>
      </li>
      <li class="mr-3">
        <router-link class="inline-block rounded py-1 px-3 bg-white-500 text-white font-mono" to="/about">About</router-link>
      </li>
      <li class="mr-3">
        <div class="inline-block rounded py-1 px-3 bg-white-500 text-white font-mono curs" @click="openSave('open')">Open</div>
      </li>
      <li class="mr-3">
        <div class="inline-block rounded py-1 px-3 bg-white-500 text-white font-mono curs" v-if="a4Data.profile.length > 10" @click="openSave('save')">Save</div>
      </li>
      <li class="mr-3" v-show="$route.name === 'Pages'">
        <div class="inline-block rounded py-1 px-3 bg-white-500 text-white font-mono curs" v-if="a4Data.profile.length > 10" @click="openSave('export')">Export</div>
      </li>
    </ul>

   <ul class="flex ml-auto mob" :class="{ show : menu }" @mouseup="menu = false" @change="menu = false">
      <li class="mr-3">
        <select class="text-white bg-gray-500 py-1 px-3 capitalize" v-model="selectedTheme">
          <option class="text-gray-300 bg-gray-500 py-1 px-3 capitalize" v-for="theme in themes" :value="theme" :key="theme">{{theme}}</option>
        </select>
      </li>
      <li class="mr-3">
        <router-link class="inline-block border-white-500 rounded py-1 px-3 bg-white-500 text-white font-mono" to="/">Details</router-link>
      </li>
      <li class="mr-3">
        <router-link class="inline-block border-white-500 rounded py-1 px-3 bg-white-500 text-white font-mono" v-if="a4Data.profile.length > 10" to="/pages">Preview</router-link>
      </li>
      <li class="mr-3">
        <router-link class="inline-block rounded py-1 px-3 bg-white-500 text-white font-mono" to="/about">About</router-link>
      </li>
      <li class="mr-3">
        <div class="inline-block rounded py-1 px-3 bg-white-500 text-white font-mono curs" @click="openSave('open')">Open</div>
      </li>
      <li class="mr-3">
        <div class="inline-block rounded py-1 px-3 bg-white-500 text-white font-mono curs" v-if="a4Data.profile.length > 10" @click="openSave('save')">Save</div>
      </li>
      <li class="mr-3" v-show="$route.name === 'Pages'">
        <div class="inline-block rounded py-1 px-3 bg-white-500 text-white font-mono curs" v-if="a4Data.profile.length > 10" @click="openSave('export')">Export</div>
      </li>
    </ul>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Header',
  computed: {
    a4Data () { return this.$store.state },
    selectedTheme: {
      get: function () { return this.$store.state.theme },
      set: function (value) { this.$store.commit('updateTheme', value) }
    }
  },
  watch: {
  },
  data: () => {
    return {
      themes: ['modern', 'lite', 'classic'],
      menu: false
    }
  },
  methods: {
    openSave: function (choice) {
      this.$store.commit('setOpenSave', choice)
    }
  }
})
export default class Header extends Vue {}
</script>

<style lang="scss" scoped>
#nav {
  padding: 0px;
    align-content: center;
    align-items: center;
    max-width: 100vw;
    overflow: hidden;
}
h1 {
  text-align: left;
    font-size: 46px;
    display: inline;
    color: rgb(228, 255, 240);
}
.logo {
  text-align: left;
  padding: 1rem;
  margin: 0;
}
.little-logo {
    width: 40px;
    display: inline;
    margin-top: -7px;
}
.router-link-exact-active {
    color: #e98503 !important;
}
#nav a {
    color: antiquewhite;
}
.mob-menu {
  display: none;
}
.no-mob {
  display: flex;
}

.mob {
  display: none;
}
small {
  font-size: 14px;
  display: block;
  padding: 0px;
  margin: 0;
}

.curs {
  cursor: pointer;
}
@media screen and (max-width: 880px) {
  small {
    display: none;
  }
  h1 {
    font-size: 22px;
    .little-logo {
      width: 22px;
    }
  }

}

@media screen and ( max-width: 820px ) {
#nav {
  display: block;
  padding: 0px;
    align-content: center;
    align-items: center;
    max-width: 100vw;
    overflow: hidden;
}
h1 {
  width: 100vw;
  text-align: left;
    font-size: 16px;
    display: inline;
    color: rgb(228, 255, 240);
}
.logo {
  text-align: left;
  padding: 1rem;
  margin: 0;
}
.little-logo {
    width: 14px;
    display: inline;
  }
  small {
    display: none;
  }
 .no-mob {
  display: none;
}
 .mob {
   display: block;
   width: 100%;
   background-color: #1b1c36;
   padding: 0;
   transition: .5s;
   height: 0px;
 }

  .show {
    height: auto;
    padding: 1rem;
  }

 .mob-menu {
   display: block;
 }

}

</style>
