import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
let i = 0
export const store = new Vuex.Store({
  state: {
    theme: 'modern',
    name: '',
    city: '',
    email: '',
    number: '',
    github: '',
    linkedin: '',
    role: '',
    message: '',
    hobbies: '',
    profile: '',
    work_history: [{
      id: 0,
      Company: '',
      Dates: '',
      Technologies: '',
      Role: '',
      Description: ''
    }],
    unrelated: '',
    qualifications: [],
    skills: '',
    additionalSkills: '',
    skill_labels: ['Programming', 'Other Skills'],
    openSave: 'false'
  },
  mutations: {
    updateName (state, value) {
      state.name = value
    },
    updateEmail (state, value) {
      state.email = value
    },
    updateCity (state, value) {
      state.city = value
    },
    updateNumber (state, value) {
      state.number = value
    },
    updateRole (state, value) {
      state.role = value
    },
    updateLinkedin (state, value) {
      state.linkedin = value
    },
    updateGithub (state, value) {
      state.github = value
    },
    updateMessage (state, value) {
      state.message = value
    },
    updateHobbies (state, value) {
      state.hobbies = value
    },
    updateQualifications (state, value) {
      state.qualifications = value
    },
    updateProfile (state, value) {
      state.profile = value
    },
    updateSkillLabels (state, value) {
      state.skill_labels = value
    },
    updateSkills (state, value) {
      state.skills = value
    },
    updateAdditionalSkills (state, value) {
      state.additionalSkills = value
    },
    addWorkObject (state, value) {
      i = Math.max(...state.work_history.map(o => o.id), 0) + 1
      state.work_history.filter(r => r !== undefined)
      state.work_history = [...state.work_history, { id: i, ...value }]
    },
    updateWorkArray (state, value) {
      // Nasty but working
      state.work_history = value
    },
    updateUnrelated (state, value) {
      state.unrelated = value
    },
    updateTheme (state, value) {
      state.theme = value
    },
    setOpenSave (state, value) {
      if (['open', 'save', 'export', 'hidden', 'false'].includes(value)) {
        state.openSave = value
      }
    },
    importStore (state, value) {
      state.name = value.name
      state.work_history = value.work_history
      state.email = value.email
      state.number = value.number
      state.profile = value.profile
      state.qualifications = value.qualifications
      state.role = value.role
      state.city = value.city
      state.skills = value.skills
      state.additionalSkills = value.additionalSkills
      state.unrelated = value.unrelated
      state.hobbies = value.hobbies
      state.linkedin = value.linkedin
      state.github = value.github
      state.skill_labels = value.skill_labels ? value.skill_labels : ['Skills', 'Other']
      state.theme = value.theme
    },
    setIValue (state, value) {
      i = value.toNumber()
    }
  },
  actions: {
    updateWorkHistory (context, value) {
      context.commit('updateWorkArray', value)
    }
  },
  modules: {
  }
})
