<template>
  <div class="hello">
    <!-- <iframe id="iframe" src="http://172.16.1.243"></iframe> -->

    <div class="w-full lg:flex grid">
    <div class="lg:w-1/3 w-full p-3 m-0">
      <form class="bg-white shadow-md rounded sm:p-2 md:px-8 md:pt-6 md:pb-8 mb-4 ">
        <div class="identity-input mb-4 form">
          <div class="h3">
          <h3 class="block text-gray-700 text-lg text-center font-bold mb-5">
            Personal Details</h3></div><div></div>
          <label for="identity" class="block text-right text-gray-700 text-sm font-bold mb-2">
            Name</label>
          <input id="identity"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="name" :value="name" @keyup="updateName($event.target.value)" />
          <label for="email" class="block text-right text-gray-700 text-sm font-bold mb-2">
            Email</label>
          <input id="email"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="Email Address"  :value="email" @change="updateVals($event.target.id, $event.target.value)" />
          <label for="city" class="block text-right text-gray-700 text-sm font-bold mb-2">
            City</label>
          <input id="city"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="City"  :value="city" @change="updateVals($event.target.id, $event.target.value)"  />
          <label for="role" class="block text-right text-gray-700 text-sm font-bold mb-2">
            Role</label>
          <input id="role"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="Your professional title/role"  :value="role" @change="updateVals($event.target.id, $event.target.value)"  />
          <label for="number" class="block text-right text-gray-700 text-sm font-bold mb-2">
            Phone Number</label>
          <input id="number"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="number" placeholder="Number"  :value="number" @change="updateVals($event.target.id, $event.target.value)"  />
          <label for="GitHub" class="block text-right text-gray-700 text-sm font-bold mb-2">
            GitHub Page</label>
          <input id="github"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="GitHub URL" :value="github" @change="updateVals($event.target.id, $event.target.value)" />
            <label for="linkedin"
            class="block text-right text-gray-700 text-sm font-bold mb-2">
            Linkedin Profile</label>
          <input id="linkedin"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="Linkedin Profile" :value="linkedin" @change="updateVals($event.target.id, $event.target.value)"  />
        </div>
              <label>Github and Linkedin only visible if you have entered a value</label>
      </form>
    </div>

          <div class="lg:w-1/3 w-full p-3 m-0">
      <form class="bg-white shadow-md rounded sm:p-2 md:px-8 md:pt-6 md:pb-8 mb-4">
        <div class="identity-input mb-4 form">
          <div class="h3">
          <h3 class="block text-gray-700 text-lg text-center font-bold mb-5">
            Professional Profile</h3></div><div></div>
        </div>
          <textarea id="profile" @focus="profileFocussed = true" @blur="profileFocussed = false" :class="{focussed: profileFocussed}"
            class="shadow appearance-none borderrounded w-auto py-2 md:px-3 text-gray-700 mb-3 leading-tight text-grow"
            type="text" placeholder="Enter your professional profile here"  :value="profile" @change="updateVals($event.target.id, $event.target.value)"  />
          <p class="block text-gray-400 text-sm text-right mt-2 mb-0 pb-0">{{ profileWordCount }} Words</p>
      </form>
      <form class="bg-white shadow-md rounded pt-6 pb-8 mb-4 sm:p-2 ">
          <div class="identity-input mb-4 form">
            <template v-if="skill_labels">
          <input id="skill_labels[0]"
            class="block text-right text-gray-700 text-sm font-bold mb-2 inputLabel"
             :value="skill_labels[0]" @change="updateArray($event.target.id, $event.target.value)" >
          <input id="skills"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="List core technologies etc" :value="skills" @change="updateVals($event.target.id, $event.target.value)"  />
            <input id="skill_labels[1]"
            class="block text-right text-gray-700 text-sm font-bold mb-2 inputLabel"
             :value="skill_labels[1]" @change="updateArray($event.target.id, $event.target.value)" >
          <input id="additionalSkills"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="List related used technologies " :value="additionalSkills" @change="updateVals($event.target.id, $event.target.value)"  />
            <label for="qualifications" class="block text-right text-gray-700 text-sm font-bold mb-2">
            Qualifications</label>
          <input id="qualifications"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="qualifications, comma separated" :value="qualifications" @change="updateVals($event.target.id, $event.target.value)" />
            <label for="hobbies" class="block text-right text-gray-700 text-sm font-bold mb-2">
            Hobbies</label>
          <input id="hobbies"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text" placeholder="hobbies, comma separated" :value="hobbies" @change="updateVals($event.target.id, $event.target.value)" />
          </template>
          </div>
          <label>Comma separated lists, skill names can be set</label>
      </form>
    </div>

      <div ref="workHistory" id="workHistory" class="lg:w-1/3 w-full p-3 m-0">
  <!-- <draggable element="span" v-model="work_history" v-bind="dragOptions" :move="onMove">
        <transition-group name="no" class="list-group" tag="ul">
          <li class="list-group-item" v-for="element in work_history" :key="element.order">
            <i :class="element.fixed? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'" @click=" element.fixed=! element.fixed" aria-hidden="true"></i>
            {{element.name}}
            <span class="badge">{{element.order}} {{element.company}}</span>
          </li>
        </transition-group>
      </draggable> -->

      <form class="bg-white shadow-md rounded sm:p-2 md:px-8 md:pt-6 md:pb-8 mb-4">
        <div class="identity-input mb-4 form">
          <div class="h3">
          <h3 class="block text-gray-700 text-lg text-center font-bold mb-5">
            Work History</h3></div><div></div>
            <button class="add-button" @click.prevent="addWorkHistory">+</button>
            <div class="close-button"><input ref="workcloser" class="radio" type="radio" name="rd" /><span class="arrow upside-down"></span>
        </div>
        </div>
        <template v-if="work_history">
          <draggable :list="work_history" :move="updateWorkHistoryOrder">
            <div v-for="(item, index) in work_history" :key="item.id" class="jobHolder" >
              <input class="radio" type="radio" name="rd" @click="openWorkHistory = item.id; debounceRadio($event)" />
              <span class="arrow" :class="{ invert : openWorkHistory === item.id }"></span>
                <div class="tab" >
            <div class="form" :id="item.company" v-for="(value, name) in item" :key="name+index">
              <template v-if="name != 'id'">
               <label :for="name + index" class="block text-right text-gray-700 text-sm font-bold mb-2" :class="{ hidden : name === 'Company'}">
                  {{name}}</label>
               <input :id="name + index" v-if="name !== 'Description'"  :class="{ 'company' : name === 'Company'}"
                  class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline wh"
                  type="text" :placeholder="name" :value="value" @change.prevent="updateWork(index, name, $event.target.value)" />
                  <textarea class="wh shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" v-else  @change.prevent="updateWork(index, name, $event.target.value.trim())" :value="value"></textarea>
                  <div v-if="name === 'Company'" class="p-5 md:p-8"></div>
                  </template>
                </div>
            </div>
            <div class="bin-button" @click="removeWorkHisotry(index)"><i class="fa fa-trash" aria-hidden="true"></i></div>
            <div class="move-button"><i class="fa fa-arrows-alt" aria-hidden="true"></i></div>
          <hr>
          </div>
        </draggable>
        </template>
      </form>
      <div class="bg-white shadow-md rounded px-0 pt-6 pb-8 mb-4 form identity-input mb-4">
        <label for="unrelated" class="block text-right text-gray-700 text-sm font-bold mb-2">
            Unrelated Job History</label>
          <textarea id="unrelated"  @focus="unrelatedFocus = true" @blur="unrelatedFocus = false" :class="{relFocussed: unrelatedFocus}"
            class="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline lines-3 text-grow"
            type="text" placeholder="Enter your previous work unrelated to the role"  :value="unrelated" @change="updateVals($event.target.id, $event.target.value)"  />
            </div>
    </div>
  </div>
  </div>
</template>

<script>
import {
  Component,
  Vue
} from 'vue-property-decorator'
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'
const WorkObject = class WorkObject {
  constructor (
    Company,
    Dates,
    Role,
    Technologies,
    Description
  ) {
    this.Company = Company
    this.Dates = Dates
    this.Role = Role
    this.Technologies = Technologies
    this.Description = Description
  }
}

  @Component({
    components: {
      draggable
    },
    computed: {
      ...mapState(['name', 'email', 'number', 'linkedin', 'role', 'city', 'profile', 'github', 'work_history', 'skills', 'additionalSkills', 'qualifications', 'unrelated', 'skill_labels', 'hobbies']),
      profileWordCount () { return this.$store.state.profile ? this.$store.state.profile.replace(/['";:,.?\xbf\-!\xa1]+/g, '').match(/\S+/g).length : 0 }
    },
    created: function () {
      document.addEventListener('focusin', this.focusChanged)
    },
    beforeDestroy: function () {
      document.removeEventListener('focusin', this.focusChanged)
    },
    data () {
      return {
        numberOfJobs: 0,
        profileFocussed: false,
        unrelatedFocus: false,
        openWorkHistory: '',
        dragging: false
      }
    },
    methods: {
      // ...mapMutations(['updateVals', 'updateWork', 'updateWorkArray']),
      ...mapActions(['updateWorkHistory']),
      debounceRadio: function (event) {
        if (event.target.nextSibling.classList.contains('invert')) {
          setTimeout(() => {
            if (event.target.value === 'on') {
              this.$refs.workcloser.checked = true
              this.openWorkHistory = ''
            }
          }, 250)
        }
      },
      tidyStore: function (arr) {
        const tidy = arr.split('\n')
          .filter(res => res.length > 4)
        return tidy
      },
      updateName: function (value) {
        this.$store.commit('updateName', value)
      },
      updateVals: function (key, value) {
        this.$store.commit(`update${key[0].toUpperCase() + key.slice(1)}`, value)
      },
      updateArray: function (key, value) {
        const tmp = this.$store.state.skill_labels.slice()
        if (key.includes('[0]')) {
          tmp[0] = value
        } else {
          tmp[1] = value
        }
        this.$store.commit('updateSkillLabels', tmp)
      },
      removeEmptyWorkHistory: function () {
        let tmp = this.$store.state.work_history.slice()
        tmp = tmp.filter(r => r.id !== undefined)
        this.$store.commit('updateWorkArray', tmp)
      },
      updateWork: function (item, key, value) {
        const tmp = this.$store.state.work_history.slice()
        tmp[item][key] = value
        if (tmp) {
          this.$store.commit('updateWorkArray', tmp)
        }
      },
      removeWorkHisotry: function (index) {
        let tmp = this.$store.state.work_history.slice()
        tmp = tmp.filter(r => r.id !== index)
        tmp.forEach((r, index) => {
          r.id = index
        })
        this.$store.commit('updateWorkArray', tmp)
      },
      addWorkHistory: function (input) {
        const vals = Object.values(this.$store.state.work_history.slice(-1))
        if (vals.Company !== undefined || input) {
          if (this.$store.state.work_history.length === 0 || vals[0].Company) {
            const workOb = new WorkObject()
            this.$store.commit('addWorkObject', workOb)
          }
        }
      },
      focusChanged (event) {
        const el = event.target
        if (!el.classList.contains('wh') && !this.$refs.workcloser.checked) {
          this.$refs.workcloser.checked = true
        }
      },
      updateWorkHistoryOrder: function () {
        this.updateWorkHistory(this.work_history)
      }
    },
    mounted: function () {
      this.removeEmptyWorkHistory()
      // window.onload = function () {
      //   const myiFrame = document.getElementById('iframe')
      //   const doc = myiFrame.contentDocument
      //   doc.body.innerHTML = doc.body.innerHTML + '<style>img { max-width: 300px; height: 900px; }</style>'
      // }
    },
    props: [
      'msg'
    ]
  })

export default class Home extends Vue {
}
</script>
<style scoped lang="scss">

.company {
  position: absolute;
  width: 86%;
}

  a {
    color: #42b983;
  }

  .h3 {
    min-height: 40px !important;
  }

  h3 {
    white-space: nowrap;
    overflow: visible;
    width: min-content;
    position: absolute;
  }

.form {
  display: grid;
  grid-template-columns: 1fr 5fr;
  padding: 0 1.6rem 0 .5rem;
  label {
    line-height: 3;
    white-space: nowrap;
  }
  input {
    margin: 1px 0px 28px 12px;
    background-color: transparent;
  }
}

.tab {
  transition: .3s;
  max-height: 40px;
  overflow: hidden;
  margin: 6px 0px;
}

input:checked ~ .tab {
  transition: .3s;
  max-height: 100vh !important;
  z-index: -1;
}

.radio {
  opacity: 0;
  width: 40px;
  position: absolute;
  z-index: 1000;
  right: 10px;
  height: 30px;
}

.arrow {
  position: absolute;
  display: inline-block;
  top: 10px;
  width: 15px;
  height: 15px;
  right: 1.6rem;
  border-top: 2px solid #aaa;
  border-right: 2px solid #aaa;
  transform: rotate(135deg);
  transition: .6s;
}

.invert {
    transform: rotate(-45deg);
}
.upside-down {
  transform: rotate(-45deg);
  top: 9px;
  left:12px;
  border-color: #777;
}

.identity-input {
  position: relative !important;
}
.close-button {
   position: absolute;
  top: 0px;
  right:60px;
  border: 1px solid grey;
  border-radius: 10px;
  width: 40px;
  height: 28px;
  background-color: #eee;
  font-size: 20px;
  line-height: 28px;
  padding: 0 0 6px 0;
}
.add-button {
  position: absolute;
  top: 0px;
  right:10px;
  border: 1px solid grey;
  border-radius: 10px;
  width: 40px;
  background-color: #eee;
  font-size: 20px;
  line-height: 20px;
  padding: 0 0 6px 0;
}
.add-button:hover {
  border: 1px solid orange;
  color: orange;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

textarea {
  height: 200px;
  resize: none;
  margin: 8px 0 00 0.3rem;
}

.bin-button {
  color: lightgrey;
  position: absolute;
  top: 8px;
  left: 1rem;
  transition: .5s;
  margin-left: -26px;
  :hover {
    color: red;
    transition: .5s;
  }
}

.move-button {
   color: lightgrey;
  position: absolute;
  right: 6rem;
  top: 8px;
  text-align: right;
    transition: .5s;
  margin-left: -26px;
  :hover {
    color: orangered;
    transition: .5s;
  }
}
.import-export {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  button {
    padding: 1rem;
    margin: 0 20px;
    border: 1px solid gray;
    border-radius: 10px;
  }
}
.jobHolder {
  position: relative;
}

.inputLabel{
  width: 120px;
  padding-right: .5rem;
}

.lines-3 {
  height: 140px;
}

.text-grow {
  height: 80px;
  transition: .5s;
}

.focussed {
  height: 400px;
  transition: .6s;
}

.relFocussed {
  height: 200px;
  transition: .6s;
}

.wh {
  padding: .6rem;
}

.w-auto {
  width: 100%;
}

@media screen and ( max-width : 1330px ) {

.tab {
  transition: .3s;
  max-height: 80px;
  overflow: hidden;
  margin: 6px 0px;
}

.w-fsm {
  width: 100% !important;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;

  label {
    line-height: 3;
    white-space: nowrap;
    text-align: left;
  }
  input, textarea {
    margin: 0 !important;
    max-width: 100%;
    text-align: left;
  }
}
}

@media screen and ( max-width: 980px ) {
  .bin-button {
    margin-left: 0px;
  }
}

@media screen and (max-width: 400px) {
  .text-grow {
    margin: 0;
  }
}

#iframe ::after {
  width: 800px;
  height: auto;
}
</style>
