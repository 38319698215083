









import { Component, Vue } from 'vue-property-decorator'
import Home from '@/components/Home.vue' // @ is an alias to /src
import Header from './Header.vue'
import draggable from 'vuedraggable'

@Component({
  components: {
    Home,
    Header,
    draggable
  }
})
export default class HomeView extends Vue {}
